export const EP_AREAS_LIST = 'private.dashboard.areas.list';
export const EP_AREA_GET_BY_ID = 'private.dashboard.areas.getById';
export const EP_AREAS_UPDATE = 'private.dashboard.areas.update';

export const EP_DELIVERIES_LIST_ACTIVE = 'private.dashboard.deliveries.active.list';
export const EP_DELIVERIES_LIST_HISTORY = 'private.dashboard.deliveries.history.list';
export const EP_DELIVERIES_DRIVERS_LIST = 'private.dashboard.deliveries.drivers.list';
export const EP_DELIVERIES_WAREHOUSES_LIST = 'private.dashboard.deliveries.warehouses.list';
export const EP_DELIVERIES_GET_DRIVER_DETAILS = 'private.dashboard.deliveries.active.getDriverDetails';
export const EP_DELIVERIES_UPDATE_DELIVERY_DRIVER = 'private.dashboard.deliveries.active.updateDriver';
export const EP_DELIVERIES_GET_BY_ID = 'private.dashboard.deliveries.getById';
export const EP_DELIVERIES_DELIVERY_LOCATION = 'private.dashboard.deliveries.location.getById';

export const EP_DRIVERS_LIST = 'private.dashboard.drivers.list';
export const EP_DRIVER_GET_BY_ID = 'private.dashboard.drivers.getById';
export const EP_DRIVERS_UPDATE = 'private.dashboard.drivers.update';
export const EP_DRIVERS_CREATE = 'private.dashboard.drivers.create';
export const EP_DRIVERS_REMOVE = 'private.dashboard.drivers.delete';
export const EP_DRIVERS_REMOVE_ACTIVE_DELIVERY = 'private.dashboard.drivers.removeActiveDelivery';
export const EP_DRIVERS_SHIFTS_LIST = 'private.dashboard.drivers.shifts.list';
export const EP_DRIVERS_SHIFTS_UPDATE = 'private.dashboard.drivers.shifts.update';
export const EP_DRIVERS_SCHEDULE_LIST = 'private.dashboard.drivers.schedule.list';
export const EP_DRIVERS_SCHEDULE_UPDATE = 'private.dashboard.drivers.schedule.update';
export const EP_DRIVERS_SCHEDULE_CLONE = 'private.dashboard.drivers.schedule.clone';

export const EP_DRIVERS_DETAILS = 'private.dashboard.drivers.details';

export const EP_CATEGORIES = 'private.dashboard.categories';
export const EP_CATEGORIES_LIST = 'private.dashboard.categories.list';
export const EP_CATEGORIES_SORT = 'private.dashboard.categories.sort';
export const EP_CATEGORIES_UPLOAD_PARTNER_IMAGE = 'private.dashboard.categories.uploadPartnerImage';
export const EP_CATEGORIES_CREATE_V2 = 'private.dashboard.categories.create';
export const EP_CATEGORIES_DETAIL_V2 = 'private.dashboard.category.details';
export const EP_CATEGORY_UPDATE = 'private.dashboard.categories.update';
export const EP_CATEGORY_REMOVE_SUBCATEGORY = 'private.dashboard.categories.removeSubcategory';

export const EP_SUBCATEGORY_LIST = 'private.dashboard.subcategories.list';
export const EP_SUBCATEGORY_CREATE = 'private.dashboard.subcategories.create';
export const EP_SUBCATEGORY_UPDATE = 'private.dashboard.subcategories.update';
export const EP_SUBCATEGORY_SORT = 'private.dashboard.subcategories.sort';
export const EP_LINK_SUBCATEGORY = 'private.dashboard.categories.addSubcategory';
export const EP_PRODUCTS_BY_SUBCATEGORY = 'private.dashboard.susbcategories.products.list';
export const EP_SORT_SUBCATEGORY_PRODUCTS = 'private.dashboard.subcategories.products.sort';
export const EP_SUBCATEGORY_REMOVE_PRODUCT = 'private.dashboard.subcategories.products.remove';
export const EP_SUBCATEGORY_ADD_PRODUCT = 'private.dashboard.subcategories.products.add';
export const EP_SUBCATEGORY_PRODUCT_SEARCH = 'private.dashboard.products.search';
export const EP_SUBCATEGORY_DETAIL = 'private.dashboard.subcategories.details';

export const EP_SIGN_IN = 'private.dashboard.users.signIn';

export const EP_COLLECTION = 'private.dashboard.collections';

export const EP_COLLECTION_IMAGE_UPLOAD = 'private.dashboard.collections.uploadImage';

export const EP_BANNER_LIST = 'private.dashboard.banners.list';
export const EP_BANNER_SORT = 'private.dashboard.banners.sort';
export const EP_BANNER_DELETE = 'private.dashboard.banners.delete';
export const EP_BANNER_IMAGE_UPLOAD = 'private.dashboard.banners.uploadImage';
export const EP_BANNER_GET_BY_ID = 'private.dashboard.banners.getById';
export const EP_BANNERS_CREATE = 'private.dashboard.banners.create';
export const EP_BANNERS_UPDATE = 'private.dashboard.banners.edit';

export const EP_WAREHOUSES_LIST = 'private.dashboard.warehouses.list';
export const EP_WAREHOUSES_GET_BY_ID = 'private.dashboard.warehouses.getById';
export const EP_WAREHOUSES_UPDATE = 'private.dashboard.warehouses.update';
export const EP_STORES_GET_DELIVEROO_SETTINGS = 'private.dashboard.stores.getDeliverooSettingsById';
export const EP_STORES_UPDATE_DELIVEROO = 'private.dashboard.stores.update.deliveroo';
export const EP_STORES_UPDATE_DELIVEROO_SITE = 'private.dashboard.stores.update.deliverooSite';
export const EP_STORES_GET_CARI_STORE = 'private.dashboard.stores.cari.getById';
export const EP_STORES_UPDATE_CARI_STORE = 'private.dashboard.stores.cari.update';
export const EP_SETTINGS_GET_DELIVEROO = 'private.dashboard.settings.deliveroo.get';
export const EP_SETTINGS_UPDATE_DELIVEROO = 'private.dashboard.settings.deliveroo.edit';
export const EP_STORES_GET_JAHEZ_STORE = 'private.dashboard.stores.jahez.getById';
export const EP_STORES_UPDATE_JAHEZ_STORE = 'private.dashboard.stores.jahez.update';

export const EP_ORDERS_LIST = 'private.dashboard.orders.list';
export const EP_ORDERS_GET_BY_ID = 'private.dashboard.orders.getById';
export const EP_ORDERS_TODAY_LIST = 'private.dashboard.orders.today.list';
export const EP_ORDERS_HISTORY_LIST = 'private.dashboard.orders.history.list';
export const EP_ORDERS_HISTORY_EXPORT = 'private.dashboard.orders.history.export';
export const EP_ORDERS_REVIEWS_LIST = 'private.dashboard.orders.reviews';
export const EP_ORDER_ADDRESS_GET_BY_ID = 'private.dashboard.orders.address.getById';
export const EP_ORDER_ADDRESS_UPDATE = 'private.dashboard.orders.address.update';
export const EP_ORDER_RECEIPT = 'private.dashboard.orders.printReceipt';
export const EP_ORDER_PARTNER_RECEIPT = 'private.dashboard.orders.partners.printReceipt';
export const EP_ORDERS_MARK_AS_CANCELED = 'private.dashboard.orders.markAsCanceled';

export const EP_EMPLOYEES_LIST = 'private.dashboard.employees.list';
export const EP_EMPLOYEES_CREATE = 'private.dashboard.employees.create';
export const EP_EMPLOYEES_EDIT = 'private.dashboard.employees.update';

export const EP_EMPLOYEES_GET_BY_ID = 'private.dashboard.employees.getById';

export const EP_BATCHING_CREATE = 'private.dashboard.deliveries.batch.create';
export const EP_BATCHING_PREVIEW = 'private.dashboard.deliveries.batch.preview';
export const EP_BATCHING_GETDATA = 'private.dashboard.deliveries.batch.list';
export const EP_BATCHING_SPLIT = 'private.dashboard.deliveries.batch.split';

export const EP_SANDBOX_RUN_BATCHING = 'private.dashboard.batching.sandbox.listDeliveries';

export const EP_SEGMENTS_IMPORT = 'private.dashboard.segments.import';
export const EP_SEGMENTS_CREATE = 'private.dashboard.segments.create';
export const EP_SEGMENTS_LIST = 'private.dashboard.segments.list';

export const EP_PAYMENT_SETTINGS_SET = 'private.dashboard.settings.payments.save';
export const EP_PAYMENT_SETTINGS_GET = 'private.dashboard.settings.payments.get';

export const EP_REPORTS_CASH_GET = 'private.dashboard.reports.cashreport.get';

export const EP_LOYALTY_LIST = 'private.dashboard.loyaltyPrograms.list';
export const EP_LOYALTY_CREATE = 'private.dashboard.loyaltyPrograms.create';
export const EP_LOYALTY_UPDATE = 'private.dashboard.loyaltyPrograms.update';

export const PRODUCT_SEARCH = 'products.search';

export const EP_DASHBOARD_SEARCH = 'private.dashboard.search';

export const EP_OFFERS_LIST = 'private.dashboard.personalizedOffers.list';
export const EP_OFFERS_ADD = 'private.dashboard.personalizedOffers.add';
export const EP_OFFERS_DELETE = 'private.dashboard.personalizedOffers.delete';
export const EP_OFFERS_EDIT = 'private.dashboard.personalizedOffers.edit';
export const EP_OFFERS_DETAIL = 'private.dashboard.personalizedOffers.details';
export const EP_OFFERS_CUSTOMERS_REACH = 'private.dashboard.personalizedOffers.getCustomersReach';
export const EP_PRODUCT_LIST = 'private.dashboard.products.list';

export const EP_CUSTOMER_SEGMENT_LIST = 'private.dashboard.customerSegments.list';
