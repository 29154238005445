import { GiftOutlined, FolderOutlined, PartitionOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { PERMISSIONS } from 'modules/permission';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const contentV2: NavItemType = {
  id: 'contentv2',
  title: <FormattedMessage id="contentsv2" />,
  type: 'group',
  permission: PERMISSIONS.PRODUCTS.READ | PERMISSIONS.CATEGORIES.READ | PERMISSIONS.COLLECTIONS.READ,
  children: [
    {
      id: 'categories-new',
      title: <FormattedMessage id="categories" />,
      type: 'item',
      url: '/contents-v2/categories',
      icon: FolderOutlined,
      permission: PERMISSIONS.CATEGORIES.READ,
      disabled: process.env.REACT_APP_ENV !== 'Staging'
    },
    {
      id: 'subcategories',
      title: <FormattedMessage id="subcategories" />,
      type: 'item',
      url: '/contents-v2/subcategories',
      icon: PartitionOutlined,
      permission: PERMISSIONS.CATEGORIES.READ,
      disabled: process.env.REACT_APP_ENV !== 'Staging'
    },
    {
      id: 'products',
      title: <FormattedMessage id="products" />,
      type: 'item',
      url: '/contents-v2/products',
      icon: GiftOutlined,
      disabled: process.env.REACT_APP_ENV !== 'Staging',
      permission: PERMISSIONS.PRODUCTS.READ
    }
  ]
};

export default contentV2;
