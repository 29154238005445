import { CrownOutlined, PictureOutlined, UsergroupAddOutlined, DollarOutlined, PercentageOutlined, TagsOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { PERMISSIONS } from 'modules/permission';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const marketing: NavItemType = {
  id: 'marketing',
  title: <FormattedMessage id="marketing" />,
  type: 'group',
  permission: PERMISSIONS.BANNERS.READ | PERMISSIONS.DISCOUNTS.READ | PERMISSIONS.SEGMENTS.READ,
  children: [
    {
      id: 'banners',
      title: <FormattedMessage id="banners" />,
      type: 'item',
      url: '/marketing/banners',
      icon: PictureOutlined,
      permission: PERMISSIONS.BANNERS.READ
    },
    {
      id: 'discounts',
      title: <FormattedMessage id="discounts" />,
      type: 'item',
      url: '/marketing/discounts',
      icon: PercentageOutlined,
      permission: PERMISSIONS.DISCOUNTS.READ
    },
    {
      id: 'segments',
      title: <FormattedMessage id="segments" />,
      type: 'item',
      url: '/marketing/segments',
      icon: UsergroupAddOutlined,
      permission: PERMISSIONS.SEGMENTS.READ
    },
    {
      id: 'compensations',
      title: <FormattedMessage id="compensations" />,
      type: 'item',
      url: '/marketing/compensations',
      icon: DollarOutlined,
      permission: PERMISSIONS.DISCOUNTS.READ
    },
    {
      id: 'loyalty',
      title: <FormattedMessage id="loyalty" />,
      type: 'item',
      url: '/marketing/loyalty',
      icon: CrownOutlined,
      permission: PERMISSIONS.DISCOUNTS.READ
    },
    {
      id: 'offers',
      title: <FormattedMessage id="offers" />,
      type: 'item',
      url: '/marketing/offers',
      icon: TagsOutlined,
      permission: PERMISSIONS.OFFERS.READ
    }
    // Obsolete
    // {
    //   id: 'recommendations',
    //   title: <FormattedMessage id="recommendations" />,
    //   type: 'item',
    //   url: '/marketing/recommendations',
    //   icon: BuildOutlined,
    //   permission: PERMISSIONS.PRODUCTS.READ
    // }
  ]
};

export default marketing;
