import { PartitionOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { PERMISSIONS } from 'modules/permission';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const content: NavItemType = {
  id: 'content',
  title: <FormattedMessage id="contents" />,
  type: 'group',
  permission: PERMISSIONS.PRODUCTS.READ | PERMISSIONS.CATEGORIES.READ | PERMISSIONS.COLLECTIONS.READ,
  children: [
    {
      id: 'categories',
      title: <FormattedMessage id="categories" />,
      type: 'item',
      url: '/contents/categories',
      icon: PartitionOutlined,
      disabled: false,
      permission: PERMISSIONS.CATEGORIES.READ
    },
    {
      id: 'collections',
      title: <FormattedMessage id="collections" />,
      type: 'item',
      url: '/contents/collections',
      icon: PartitionOutlined,
      disabled: false,
      permission: PERMISSIONS.COLLECTIONS.READ
    }
  ]
};

export default content;
